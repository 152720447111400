import { all, call, put, takeLatest } from 'redux-saga/effects';
import { API_ENDPOINTS } from 'src/config/api-endpoints';
import { LoginResponse } from 'src/state/api-models/auth';
import { convertToFormData } from 'src/utils/converter';
import { axiosInstance } from '../../../../utils/axios/axios-instance';
import * as actions from './actions';
import { clearAuthData, getAuthData, setAuthData, clearAuthlocalStorage } from './storage';
import * as settingInitialActions from '../setting-initial/actions';
import { setInitialSettings } from '../setting-initial/storage';
import { InitialSettingDataState } from '../setting-initial/model';

// Handle request saga
function* login(action: ReturnType<typeof actions.login.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.AUTH.Login, action.payload);

    const responseData: LoginResponse = (response as any).data;

    yield put(actions.login.success(responseData));
    if (responseData && responseData.success) {
      yield call(() => setAuthData(responseData.data));
      if (responseData.data.initial_settings) {
        yield call(() =>
          setInitialSettings({
            general: responseData.data.initial_settings.generalSettings,
            [responseData.data.store_id]: responseData.data.initial_settings.storeSettings,
          } as InitialSettingDataState)
        );
        yield put(
          settingInitialActions.setInitialGeneralSettingData(responseData.data.initial_settings.generalSettings)
        );
        yield put(
          settingInitialActions.setInitialStoreSettingData({
            storeId: responseData.data.store_id,
            data: responseData.data.initial_settings.storeSettings,
          })
        );
      }
    }

    // if (responseData.data.store_settings) {
    //   const profileSetting = { profile: responseData.data.store_settings };
    //   yield put(settingActions.setData(profileSetting));
    // }
  } catch (err) {
    yield put(actions.login.failure(err));
  }
}

function* loginToken(action: ReturnType<typeof actions.loginToken.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.AUTH.LoginToken, action.payload);

    const responseData: LoginResponse = (response as any).data;

    yield put(actions.loginToken.success(responseData));
    if (responseData && responseData.success) {
      yield call(() => setAuthData(responseData.data));
    }

    // if (responseData.data.store_settings) {
    //   const profileSetting = { profile: responseData.data.store_settings };
    //   yield put(settingActions.setData(profileSetting));
    // }
  } catch (err) {
    yield put(actions.loginToken.failure(err));
  }
}

function* logout(action: ReturnType<typeof actions.logout.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.AUTH.Logout, action.payload);

    const responseData = (response as any).data;

    yield put(actions.logout.success(responseData));
    yield put(actions.clearData());
  } catch (err) {
    yield put(actions.logout.failure(err));
  }
}

function* checkPinCode(action: ReturnType<typeof actions.checkPinCode.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.AUTH.CheckPinCode, {
      params: action.payload,
    });

    yield put(actions.checkPinCode.success((response as any).data));
  } catch (err) {
    yield put(actions.checkPinCode.failure(err));
  }
}

function* getInitialSettings(action: ReturnType<typeof actions.getInitialSettings.request>): Generator {
  try {
    const response = yield call(axiosInstance.get, API_ENDPOINTS.AUTH.InitialSettings, {
      params: action.payload,
    });

    const responseData = (response as any).data;
    const initialSettings = responseData.data;

    const loginData = getAuthData();
    loginData.initial_settings = initialSettings;

    yield put(actions.getInitialSettings.success(responseData));
    yield put(actions.updateInitialSettings(initialSettings));

    yield call(() => setAuthData(loginData));
  } catch (err) {
    yield put(actions.getInitialSettings.failure(err));
  }
}

function* updateProfile(action: ReturnType<typeof actions.updateProfile.request>): Generator {
  try {
    const response = yield call(axiosInstance.post, API_ENDPOINTS.AUTH.Profile, action.payload, {
      headers: { 'Content-Type': 'multipart/form-data' },
      transformRequest: (data) => convertToFormData(data),
    });

    const responseData = (response as any).data;
    yield put(actions.updateProfile.success(responseData));

    const loginData = getAuthData();
    loginData.user = { ...loginData.user, ...responseData.data };
    yield call(() => setAuthData(loginData));
  } catch (err) {
    yield put(actions.getInitialSettings.failure(err));
  }
}

function* clearData(action: ReturnType<typeof actions.clearData>): Generator {
  yield call(() => clearAuthData());
  yield call(() => clearAuthlocalStorage());
}

// Main saga
export default function* authSaga() {
  yield all([takeLatest(actions.login.request, login)]);
  yield all([takeLatest(actions.loginToken.request, loginToken)]);
  yield all([takeLatest(actions.logout.request, logout)]);
  yield all([takeLatest(actions.clearData, clearData)]);
  yield all([takeLatest(actions.clearData, clearData)]);
  yield all([takeLatest(actions.checkPinCode.request, checkPinCode)]);
  yield all([takeLatest(actions.getInitialSettings.request, getInitialSettings)]);
  yield all([takeLatest(actions.updateProfile.request, updateProfile)]);
}
