import { PAGE_PATH } from 'src/routers/route-path';

export type ScreenKeys =
  | 'dashboard'
  | 'employee_checkin'
  | 'sale'
  | 'cashier'
  | 'walkin'
  | 'schedule'
  | 'turn_system'
  | 'report'
  | 'payroll'
  | 'employee_income_report'
  | 'employee_income_report_detail'
  | 'employee_income_hr_report'
  | 'employee_income_hr_report_detail'
  | 'employee_check_inout_report'
  | 'customer_report'
  | 'customer_report_detail'
  | 'giftcard_report'
  | 'giftcard_report_detail'
  | 'service_product_report'
  | 'service_product_report_detail'
  | 'summary_report'
  | 'summary_report_detail'
  | 'store_report'
  | 'store_report_detail'
  | 'daily_report'
  | 'daily_report_detail'
  | 'emarketing'
  | 'sms'
  | 'customer'
  | 'customer_group'
  | 'membership'
  | 'giftcard'
  | 'coupon'
  | 'member'
  | 'supplier'
  | 'category'
  | 'unit'
  | 'service'
  | 'product'
  | 'package'
  | 'expense'
  | 'setting';

export enum Screen {
  Dashboard = 1,
  EmployeeCheckin,
  Sale,
  Walkin,
  Schedule,
  TakeTurn,
  Report,
  Payroll,
  Emarketing,
  Sms,
  Customer,
  CustomerGroup,
  Membership,
  Giftcard,
  Coupon,
  Member,
  Supplier,
  Category,
  Unit,
  Service,
  Product,
  Package,
  Expense,
  Setting,
}

export const Screens = [
  {
    label: 'Dashboard',
    value: 'dashboard',
  },
  {
    label: 'Employees Check-In',
    value: 'employee_checkin',
  },
  {
    label: 'Sales',
    value: 'sale',
  },
  {
    label: 'Walkin',
    value: 'walkin',
  },
  {
    label: 'Schedules',
    value: 'schedule',
  },
  {
    label: 'Take turns',
    value: 'turn_system',
  },
  {
    label: 'eMarketing',
    value: 'emarketing',
  },
  {
    label: 'SMS',
    value: 'sms',
  },
  {
    label: 'Gift cards',
    value: 'giftcard',
  },
  {
    label: 'Coupons',
    value: 'coupon',
  },
  {
    label: 'Reports',
    value: 'report',
  },
  {
    label: 'Payrolls',
    value: 'payroll',
  },
  {
    label: 'Customer',
    value: 'customer',
  },
  {
    label: 'Customer Group',
    value: 'customer',
  },
  {
    label: 'Membership',
    value: 'membership',
  },
  {
    label: 'Category',
    value: 'category',
  },
  {
    label: 'Units',
    value: 'unit',
  },
  {
    label: 'Products',
    value: 'product',
  },
  {
    label: 'Services',
    value: 'service',
  },
  {
    label: 'Packages',
    value: 'package',
  },
  {
    label: 'Expenses',
    value: 'expense',
  },
  {
    label: 'Suppliers',
    value: 'supplier',
  },
  {
    label: 'Members',
    value: 'member',
  },
  {
    label: 'Settings',
    value: 'setting',
  },
];

export const DefaultHomePages = [
  { value: 'employee_checkin', label: 'Employees check-in dashboard' },
  { value: 'schedule', label: 'Schedule dashboard' },
  { value: 'turn_system', label: 'Take turn dashboard' },
  { value: 'walkin', label: 'Walk-in dashboard' },
  { value: 'dashboard', label: 'Dashboard' },
];

export const ScreensRequirePinCode = [
  PAGE_PATH.IncomeReport,
  PAGE_PATH.IncomeReportDetail,
  PAGE_PATH.SummaryReport,
  PAGE_PATH.SummaryReportDetail,
];
