import produce, { Draft } from 'immer';
import { combineReducers } from 'redux';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import {
  initialSettingState,
  SettingDataState,
  SettingListState,
  SettingUpdateManyState,
  SettingUpdateState,
} from './model';

const data = produce((draft: Draft<SettingDataState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.setData): {
      const { storeId, data } = action.payload;
      draft[storeId] = { ...draft[storeId], ...data };
      return draft;
    }
    case getType(actions.hasUpdatedData): {
      draft.updatedAt = new Date().toISOString();
      return draft;
    }
    case getType(actions.clearGroupData): {
      const { storeId, code } = action.payload;

      if (draft[storeId] && draft[storeId][code]) {
        draft[storeId][code] = {};
      }
      return draft;
    }
    default:
      return draft;
  }
}, initialSettingState.data);

const list = produce((draft: Draft<SettingListState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.getSettingList.request): {
      draft.loading = true;
      return draft;
    }
    case getType(actions.getSettingList.success): {
      draft.loading = false;
      draft.response = action.payload;
      return draft;
    }
    case getType(actions.getSettingList.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }
    default:
      return draft;
  }
}, initialSettingState.list);

const update = produce((draft: Draft<SettingUpdateState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.updateSettings.request): {
      draft.loading = true;
      return draft;
    }
    case getType(actions.updateSettings.success): {
      draft.loading = false;
      draft.response = action.payload;
      return draft;
    }
    case getType(actions.updateSettings.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      draft = initialSettingState.update;
      return draft;
    }
    default:
      return draft;
  }
}, initialSettingState.update);

const updateMany = produce((draft: Draft<SettingUpdateManyState>, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.updateManySettings.request): {
      draft.loading = true;
      return draft;
    }
    case getType(actions.updateManySettings.success): {
      draft.loading = false;
      draft.response = action.payload;
      return draft;
    }
    case getType(actions.updateManySettings.failure): {
      draft.loading = false;
      draft.errors = action.payload;
      return draft;
    }
    case getType(actions.clearData): {
      draft = initialSettingState.updateMany;
      return draft;
    }
    default:
      return draft;
  }
}, initialSettingState.updateMany);

export default combineReducers({
  list,
  data,
  update,
  updateMany,
});
